interface Page<T> {
  items: T[]
}

export interface CategoryGroupType {
  handle: string
  i18nKey: string
  description: Record<string, string>
}

interface SubCategoryGroup extends CategoryGroupType {}

export const useCategoryGroup = defineStore('category-group', () => {
  const categoryGroup = ref<CategoryGroupType[]>([])
  const categories = ref<CategoryGroupType[]>([])
  const subCategories = ref<SubCategoryGroup[]>([])

  function getCategoryGroups() {
    (async function () {
      try {
        const request = useFetch<Page<CategoryGroupType>>(`/api/easyApi/categories`)
        const { data, error } = await request
        if (error.value)
          throw error.value
        categoryGroup.value = data.value?.items ?? []
      }
      catch (err) {
        console.error(`Failed to fetch category groups`, err)
      }
    })()
  }

  function getCategories(handle: string) {
    (async () => {
      try {
        const request = useFetch<Page<CategoryGroupType>>(`/api/easyApi/categories?gHandle=${handle}`)
        const { data, error } = await request
        if (error.value)
          throw error.value
        categories.value = data.value?.items ?? []
      }
      catch (err) {
        console.error(`Failed to fetch categories for handle ${handle}:`, err)
      }
    })()
  }

  function getSubCategories(handle: string) {
    (async function () {
      try {
        const request = useFetch<Page<SubCategoryGroup>>(`/api/easyApi/categories?cHandle=${handle}`)
        const { data, error } = await request
        if (error.value)
          throw error.value
        subCategories.value = data.value?.items ?? []
      }
      catch (err) {
        console.error(`Failed to fetch subcategories for handle ${handle}:`, err)
      }
    })()
  }

  function $reset() {
    categoryGroup.value = []
    categories.value = []
    subCategories.value = []
  }

  return {
    categoryGroup,
    categories,
    subCategories,
    $reset,
    getCategoryGroups,
    getCategories,
    getSubCategories,
  }
})
